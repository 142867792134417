import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import KitPage from '~/components/Kit/Page';

const KitTemplate = ({ data }) => {
  const { kit } = data;

  return (
    <Layout>
      <Metadata title={kit.name} description={kit.shortDescription} />
      <KitPage kit={kit} />
    </Layout>
  );
};

export const query = graphql`
  query KitQuery($slug: String, $locale: String) {
    kit: contentfulKit(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...KitPageFragment
    }
  }
`;

export default KitTemplate;
