/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, Container } from 'theme-ui'
import Quote from '~/components/Generic/Quote'

const KitQuote = ({ quote }) => {
  return (
    <Container
      variant="fullWidth"
      sx={{
        backgroundColor: 'altBackground'
      }}
    >
      <Container>
        <Quote quote={quote} />
      </Container>
    </Container>
  )
}

KitQuote.propTypes = {
  quote: PropTypes.shape({
    author: PropTypes.string,
    location: PropTypes.string,
    body: PropTypes.shape({})
  })
}

export default KitQuote
