/** @jsx jsx */
import { Fragment } from 'react'
import { Box, Container, Flex, jsx } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import RelatedProducts from '~/components/Generic/RelatedProducts'
import KitDetails from '~/components/Kit/Details'
import KitImages from '~/components/Kit/Images'
import KitQuote from '~/components/Kit/Quote'

const KitPage = ({ kit }) => {
  const { images, relatedProducts, quote } = kit

  return (
    <Fragment>
      <Container p={0}>
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            marginTop: ['0', null, '32px']
          }}
        >
          <Box
            sx={{
              variant: 'responsive.mobileTablet',
              width: ['92%', '60%'],
              margin: 'auto',
              '& .carousel-slider': {
                overflow: 'visible'
              },
              '& .slider-wrapper': {
                overflow: 'visible'
              }
            }}
          >
            <ImageCarousel images={images} />
          </Box>
          <Box sx={{ variant: 'responsive.desktop', width: '100%' }}>
            <KitImages images={images} />
          </Box>
          <KitDetails
            kit={kit}
            sx={{
              maxWidth: ['100%', '100%', '50%'],
              paddingLeft: ['1rem', null, '2rem'],
              paddingRight: ['1rem', null, '2rem']
            }}
          />
        </Flex>
      </Container>

      <Container variant="fullWidth">
        {quote && <KitQuote quote={quote} />}
      </Container>

      <Container>
        {relatedProducts && <RelatedProducts products={relatedProducts} />}
      </Container>
    </Fragment>
  )
}

export default KitPage
