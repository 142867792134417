/** @jsx jsx */
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Flex, Grid, jsx, Text } from 'theme-ui';
import { useAnalytics, useTranslate } from '@arfabrands/gatsby-theme-assembly';

const IncludedProducts = ({ products }) => {
  const translate = useTranslate();
  const { trackClickProduct } = useAnalytics();

  return (
    <Box py="1rem">
      <Text
        sx={{
          variant: 'text.body',
          marginBottom: '1rem',
        }}
      >
        {translate('product.included_products')}
      </Text>
      <Grid gap={[2, 3]} columns={1}>
        {products.map(({ name, slug, variants, collection }) => {
          const url = `/products/${slug}/`;
          return (
            <Flex
              key={slug}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                margin: 0,
                border: '1px solid',
                backgroundColor: 'white',
                height: '100%',
              }}
            >
              <Link to={url} onClick={() => trackClickProduct(slug)}>
                <Img
                  fluid={variants[0].mainImage.fluid}
                  alt={slug}
                  key={slug}
                  sx={{ width: ['70px', null, '86px'], height: '100%' }}
                />
              </Link>
              <Flex
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  margin: ['12px', null, '16px'],
                }}
              >
                <Link
                  sx={{
                    display: 'block',
                    textTransform: 'none',
                    variant: ['text.h4'],
                    textAlign: 'left',
                  }}
                  to={url}
                >
                  {name}
                </Link>
                <Flex
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    variant: ['text.navLink'],
                    lineHeight: '24px',
                  }}
                >
                  {collection && <Link to={url}>{collection.title}</Link>}
                  {/* {size && <Link to={url}>{size}</Link>} */}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Grid>
    </Box>
  );
};

export default IncludedProducts;
